.violationList {
    &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid #c2c9d1;
        border-bottom: 1px solid #c2c9d1;
        padding: 15px;
        img {
            margin-right: 7px;
        }
    }
    &__delete {
        margin-top: 30px;
        margin-bottom: 30px;
        margin-right: auto;
    }
}

.stopList {
    &__expand {
        width: 14px;
        height: 14px;
        position: absolute;
        right: 18px;
        top: 50%;
        transform: translateY(-50%);
        img {
            transform: rotate(180deg);
        }
        &--active {
            img {
                transform: rotate(0);
            }
        }
    }

    &__cancel {
        position: absolute;
        right: 47px;
        top: 50%;
        transform: translateY(-50%);
        width: 14px;
        height: 14px;
    }
    &__button {
        margin-left: auto;
    }
}
