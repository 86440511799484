.table {
    width: 100%;
    border-collapse: collapse;
    &__headers {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: rgba(0, 0, 0, 0.5);
        th {
            border-bottom: 1px solid #c2c9d1;
        }
    }
    &__adsName {
        button {
            color: #2e8299;
            max-width: 210px;
        }
    }
    &__action {
        &-button {
            margin-right: 12px;
            &:nth-last-child(1) {
                margin-right: 0;
            }
            &--disabled {
                opacity: 0.6;
                cursor: default;
            }
        }
    }
    th {
        padding: 15px;
    }
    td {
        word-break: break-word;
        padding: 15px;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
    }
}

.usersTable {
    &__name {
        color: #000000;
    }
    &__email {
        color: rgba(0, 0, 0, 0.5);
    }
    &__link {
        color: #000000;
        display: flex;
        align-items: center;
    }
    td,
    th {
        text-align: start;
    }
    td {
        border-bottom: 1px solid rgba(#c2c9d1, 0.5);
    }
    &__posts {
        text-align: end !important;
    }
}

.categoriesTable {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    td {
        border-bottom: 1px solid rgba(#c2c9d1, 0.5);
    }
}
