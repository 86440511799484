.createPost {
  .ql-editor {
    min-height: 200px !important;
  }
  &__filebox {
    margin-top: 40px;
  }
  &__wrap {
    position: relative;
  }
  &__title {
    margin-bottom: 40px;
    position: relative;
  }
  &__subtitle {
    margin-bottom: 60px;
    text-align: center;
    max-width: 669px;
  }
  &__header {
    margin-bottom: 20px;
    text-align: center;
  }
  &__text {
    margin-bottom: 28px;
    text-align: center;
    max-width: 658px;
  }
  &__list {
    width: 382px;
    margin-bottom: 20px;
  }
  &__buttons {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  &__cancel {
    width: 320px;
  }
  &__next {
    color: #f5f4e8;
    width: 320px;
    margin-bottom: 20px;
  }
  &__form {
    width: 368px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }
  &__input {
    padding: 9.5px 17px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    width: 100%;
    background: #f1f1f1;
    color: #050138;
    font-size: 16px;
    line-height: 19px;
    border: 2px solid transparent;
    transition: 0.2s ease-in;

    &:focus {
      border: 2px solid rgba(#000000, 0.1);
    }
    &:disabled {
      background-color: #f1f1f1;
      box-shadow: none;
    }
    &-cancel {
      position: absolute;
      right: 21px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-confirm {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 52px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__hint {
    padding-top: 20px;
    padding-bottom: 26px;
  }
  &__editButtons {
    position: absolute;
    left: 105%;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    &-delete {
      cursor: pointer;
    }
    &-edit {
      cursor: pointer;
      margin-right: 16px;
      width: 18px;
    }
  }
  &__goBack {
    position: absolute;
    top: 25px;
    right: 25px;

    img {
      height: 25px;
      width: 25px;
    }
  }
}

.filebox {
  width: 350px;
  height: 365px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url('../../assets/img/defaultUpload.png');
  &__button {
    width: 208px;
    margin-bottom: 20px;
    height: 38px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2e8299;
    border-radius: 8px;
    transition: background-color 0.2s ease-in;
    &:hover {
      background-color: #249abb;
    }
    img {
      width: 30px;
    }
  }

  &__text {
    position: absolute;
    bottom: 120px;
    font-size: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.chooseFilter {
  &__preview {
    width: 600px;
    height: 600px;
    overflow: hidden;
    justify-content: center;
    display: flex;
    border-radius: 16px;
    margin-bottom: 28px;
    position: relative;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  &__subtitle {
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
  }
  &__filters {
    margin-top: 11px;
  }
}

.chooseCategory {
  &__error {
    position: absolute;
    top: 110%;
    color: #da100b;
  }
}

.filters {
  width: 600px;
  display: flex;
  justify-content: space-between;
  &__thumbnail {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 190px;
    height: 190px;
    border-radius: 16px;
    position: relative;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    &--active {
      border: 2px solid #2e8299;
    }
  }
}

.coolBlue {
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: radial-gradient(
      circle,
      rgba(146, 164, 255, 0.4) 0%,
      rgba(3, 14, 70, 0.4) 100%
    );
  }
}

.coolWarm {
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: yellow;
    opacity: 0.3;
  }
}

.normal {
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }
}

.inReview {
  &::before {
    content: 'In review';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: rgba(#fff, 0.5);
    opacity: 1;
    left: 0;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  // &::before {
  //     content: "In review";
  //     position: absolute;
  //     top: 50%;
  //     left: 50%;
  //     transform: translate(-50%, -50%);
  //     z-index: 50;
  //     font-size: 20px;
  // }
}

.chooseText {
  &__input {
    width: 600px;
    background: rgba(#2e8299, 0.2);
    resize: none;
    border-radius: 8px;
    // height: 200px;
    min-height: 200px;
    font-size: 16px !important;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #111424;
    margin-top: 25px;
    &::placeholder {
      color: rgba(#111424, 0.8);
    }
    &--warning {
      background-color: tomato;
      font-size: 16px;
    }
  }
  &__preview {
    background-color: rgba(#050138, 0.2);
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 600px;
    height: 600px;
    margin-bottom: 40px;
    position: relative;
  }
  &__audioImage {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &__audioButton {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    img {
      width: 150px;
      height: 150px;
    }
  }
}
.selectBackground {
  color: #ffffff;
  width: 208px;
  border-radius: 10px;
  margin-bottom: 20px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #39363e;
  &:hover {
    background-color: #434049;
  }
}
