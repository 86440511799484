@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.steamer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-top: -25px;

    &__block {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 15px 20px;
        border-bottom: 0.5px solid #c2c2c2;

        &-left {
            display: flex;
        }

        &-text {
            display: flex;
            justify-content: center;
            flex-direction: column;
            margin-left: 15px;
        }

        &-nickName {
            font-family: "Poppins", sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            color: #39363e;
        }
        &-fullName {
            font-family: "Poppins", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 19px;
            color: #828282;
        }

        &-button {
            position: relative;

            &ToolTip {
                right: 0;
                bottom: -35px;
                min-width: 120px;
                position: absolute;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 10px;
                background: #ffffff;
                box-shadow: 2px 2px 50px rgba(0, 0, 0, 0.25);
                border-radius: 9px;

                font-family: "Poppins", sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;
                /* identical to box height */

                color: #39363e;
            }
        }
    }
}
