.categories {
    &__tabs {
        position: absolute;
        top: 0;
        left: 0;
    }

    &__wrap {
        padding-top: 89px;
        align-items: initial;
        padding-left: 50px;
        padding-right: 50px;
    }
    &__searchPannel {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        justify-content: flex-end;
        padding-left: 40px;
        padding-right: 40px;
        margin-bottom: 32px;
        position: relative;
        .post__actions-hint {
            width: 200px;
        }
        .post__actions-hintElement {
            padding: 15px;
        }
    }
    &__searchbar {
        width: 576px;
        margin-right: 0 !important;
    }
    &__sort {
        display: flex;
        align-items: center;
    }
}
