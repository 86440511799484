.background {
    &__container {
        position: relative;
        min-height: 70vh;
        .react-ripples {
            margin-left: auto;
            margin-right: auto;
            display: flex !important;
            justify-content: center;
            align-items: center;
        }
    }
}

.wrapBtn {
    display: flex;
    align-items: center;
    margin-bottom: 29px;
    position: absolute;
    z-index: 999;
    justify-content: space-between;
    width: 20%;
    // top: -100%;
    top: -23px;
    right: 30px;
    transform: translateY(-100%);
    button {
        cursor: pointer;
        width: 118px;
        height: 38px;
        &:first-child {
            border: 1px solid #2e8299;
            box-sizing: border-box;
            border-radius: 8px;
            color: #2e8299;
        }
        &:last-child {
            background: #2e8299;
            border-radius: 8px;
            color: #fff;
        }
    }
    @media screen and (max-width: 1200px) {
        width: 27%;
        right: 16px;
    }
}
.wrapCard {
    width: 900px;
    height: auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: center;
    @media screen and (max-width: 1200px) {
        width: 500px;
    }
    .cardItem {
        position: relative;
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
        
        p {
            font-size: 23px;
            line-height: 34px;
            color: #fff;
            opacity: 0.8;
            z-index: 999;
        }

        height: 254px;
        margin: 10px !important;
        @media screen and (max-width: 1200px) {
            height: 150px;
        }
        margin-right: 5px;
        margin-bottom: 20px;
        .mainPicture {
            position: absolute;
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 15px;
        }
        label {
            cursor: pointer;
            position: absolute;
            z-index: 999;
            top: 12px;
            right: 20px;
            @media screen and (max-width: 1200px) {
            }
        }
    }
}

.upload-photo-background {
    opacity: 0;
    position: absolute;
    z-index: -1;
}
