.modern-radio-container {
    display: flex;
    cursor: pointer;
    user-select: none;
    border-bottom: 1px solid rgba(#2e8299, 0.1);
    padding: 10px 14px;
    transition: background-color 0.2s ease-in, border-bottom 0.2s ease-in;
    &:nth-child(1) {
        border-radius: 4px 4px 0px 0px;
    }
    &:hover {
        background-color: rgba(#2e8299, 0.1);
        border-bottom: 1px solid transparent;
    }
}

.radio-outer-circle {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    border: 2px solid #2e8299;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    transition: all 0.1s linear;
}

.radio-inner-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #2e8299;
    transition: all 0.1s linear;
}
.unselected {
    border: 2px solid #bdbdbd;
}
.unselected-circle {
    width: 0;
    height: 0;
}
.helper-text {
    color: #bdbdbd;
    padding-right: 8px;
}
