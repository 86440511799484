.users {
  &__searchbar {
    margin-right: 100px !important;
    margin-left: 100px !important;
    margin-bottom: 40px;
  }
  &__wrap {
    align-items: initial;
  }
}

.profile {
  &__wrap {
    width: 955px;
    min-height: 50vh;
    display: flex;
    // justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-right: 32px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
    padding: 40px 46px 100px 47px;
  }
  &__container {
    display: flex;
    width: fit-content;
    align-items: flex-start;
    padding: 0 30px;
    margin: 0 auto;
    margin-top: 47px;
    padding-left: -140px;
    min-height: 70vh;
  }
  &__editButton {
    display: flex;
    justify-content: center;
    align-items: baseline;
    span {
      margin-left: 11px;
    }
  }
  &__user {
    display: flex;
    flex-direction: column;
    margin-bottom: 43px;
    &-name {
      display: flex;
      width: 525px;
      justify-content: space-between;
      margin-bottom: 20px;
    }
    &-info {
      width: 438px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    &-fullName {
      flex-basis: 100%;
      margin-bottom: 27px;
      margin-top: 27px;
    }
    &-bio {
      flex-basis: 100%;
      max-width: 428px;
      color: #111424;
      word-wrap: break-word;
    }
  }
  &__posts {
    display: flex;
    width: 822px;
    margin-right: -20px;
    margin-top: 20px;
    flex-wrap: wrap;
  }
  &__defaultPost {
    width: 254px;
    height: 254px;
    margin-right: 20px;
    background: rgba(#2e8299, 0.2);
    border-radius: 16px;
    border: 2px dashed #2e8299;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2e8299;
  }
  &__button {
    margin-top: 20px;
    margin-bottom: 25px;
  }
  &__album {
    width: 254px;
    height: 254px;
    cursor: pointer;
    margin-right: 20px;
    // margin-right: 20px;
    background-color: rgba(#050138, 0.2);
    overflow: hidden;
    border-radius: 16px;
    margin-bottom: 20px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &--image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &--audio {
      margin-top: 20px;
      width: 140px;
    }
    &--text {
      width: 187px;
    }
    &--video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &-play {
      opacity: 0.6;
      width: 75px;
      height: 75px;
      left: 50%;
      top: 50%;
      position: absolute;
      transform: translate(-40%, -50%);
    }

    &-empty {
      opacity: 0;
      width: 254px;
      height: 254px;
    }
    &-delete {
      position: absolute;
      bottom: 0;
      z-index: 1000;
      right: 0;
      transition: transform 0.2s ease-in;
      width: 50px;
      height: 50px;
      &:hover {
        transform: scale(110%);
        z-index: 100;
      }
    }
  }
}

.userPreview {
  &__wrap {
    flex-wrap: wrap;
  }
  &__form-group {
    margin-top: 27px;
    flex-basis: 100%;
    margin-bottom: 34px;
  }
  &__label {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #050138;
    opacity: 0.6;
    margin-bottom: 8px;
    display: block;
  }
  &__formInput {
    border: 1px solid rgba(#050138, 0.4);
    color: rgba(#050138, 0.4);
    font-size: 16px;
  }
}
