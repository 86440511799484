.userStatus {
    &__status {
        width: 62px;
        padding: 4px 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__modal {
        position: absolute;
        top: 15px;
        right: 10px;
        width: 122px;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
        border-radius: 8px;
        z-index: 50;
        &-block {
            padding: 16px 36px 16px 18px;

            border-bottom: 1px solid rgba(#e0e0e0, 0.6);
        }
        span {
            width: 66px;
            display: block;
            padding-left: 0;
            padding-right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
        &-close {
            position: absolute;
            right: 11px;
            top: 11px;
        }
    }
}
