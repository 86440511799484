.modal {
    &__top {
        border-bottom: 1px solid #e0e0e0;
        padding-bottom: 17px;
        margin-bottom: 25px;
    }
    &__body {
        padding: 0 40px;
    }
    &__bottom {
        border-top: 1px solid #e0e0e0;
        display: flex;
        padding-top: 14px;
        padding-bottom: 16px;
        justify-content: flex-end;
        margin-top: 28px;
    }
    &__paragraph {
        text-align: center;
        margin-bottom: 15px;
        width: 295px;
        margin-left: auto;
        margin-right: auto;
        color: #000000;
    }
    &__close {
        position: absolute;
        top: 17px;
        right: 17px;
    }
    &__header {
        text-align: center;
        color: #111424;
    }
    &__text {
        margin-bottom: 18px;
        margin-right: auto;
        margin-left: auto;
        margin-top: 48px;
        text-align: center;
        word-break: break-word;
    }
    &__button {
        letter-spacing: 1.25px;
        margin-left: auto;
        display: block;
        color: #2e8299;
        padding: 10px 30px;
        margin-right: 8px;
    }

    &__list {
        &-item {
            padding-top: 22px;
            padding-bottom: 22px;
            padding-left: 22px;
            border-bottom: 1px solid #e0e0e0;
            background: #ffffff;
            transition: background 0.2s ease-in;
            cursor: pointer;
            &:nth-last-child(1) {
                border: none;
            }
            &:hover {
                background: rgba(#2e8299, 0.1);
            }
        }
    }
    &__ok,
    &__cancel {
        width: 110px;
        height: 36px;
        padding: 10px 40px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.shareModal {
    position: absolute;
    left: 88px;
    bottom: 0px;
    width: 370px;
    height: 300px;
    padding-left: 24px;
    padding-top: 24px;
    padding-right: 26px;
    padding-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
    background-color: #fff;
    opacity: 0;
    z-index: -10;
    transition: opacity 0.1s ease-in;
    display: flex;
    justify-content: center;
    flex-direction: column;
    &--show {
        opacity: 1;
        z-index: 100;
    }
    &__header {
        margin-bottom: 31px;
        color: #111424;
    }
    &__close {
        position: absolute;
        top: 22px;
        right: 17px;
    }
    &__social {
        display: flex;
        margin-bottom: 24px;
        justify-content: space-between;
    }
    &__buffer {
        margin-top: 12px;
        padding: 20px 56px 20px 17px;
        background: rgba(#2e8299, 0.2);
        border-radius: 4px;
        color: #2e8299;
        position: relative;
        cursor: pointer;
        &-icon {
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.settingsModal {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
    position: absolute;
    text-align: left;
    overflow: hidden;
    right: 0;
    z-index: 10;
    top: 85%;
    width: 235px;
}

.tooltip {
    overflow: hidden;
    position: absolute;
    bottom: 140%;
    left: 50%;
    transform: translateX(-50%);
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.9);
    transition: opacity 0.3s ease-out;
    color: #ffffff;
    border-radius: 8px;
}

.followersModal {
    padding-bottom: 5px;
    &__list {
        max-height: 400px;

        &-item {
            align-items: flex-start;
            display: flex;
            padding-left: 10px;
            padding-right: 19px;
            padding-top: 9px;
            padding-bottom: 13px;
            border-bottom: 1px solid #e0e0e0;
        }
    }
    &__top {
        margin-bottom: 0;
    }
    &__button {
        margin-left: 50px;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 1.25px;
        color: #2e8299;
    }
}

.follower {
    display: flex;
    align-items: center;
    margin-right: auto;
    &__info {
        margin-left: 8px;
        &-fullName {
            color: rgba(#111424, 0.6);
        }
        &-username {
            margin-bottom: 8px;
        }
    }
}

.changePassword {
    &__top {
        padding-top: 30px;
        padding-bottom: 23px;
        margin-bottom: 40px;
    }
    &__form {
        width: 100%;
        padding: 0;

        &-group {
            width: 328px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 14px;
        }
        .modal__bottom {
            margin-top: 40px;
        }
    }
    &__formInput {
        border: 1px solid rgba(0, 0, 0, 0.12);
        // color: rgba(0, 0, 0, 0.6);
        &::placeholder {
            color: rgba(0, 0, 0, 0.6);
        }
    }
}

.createPostNotification {
    width: 328px;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin-left: auto;
    margin-right: auto;
    &__paragraph {
        text-align: center;
        margin-bottom: 16px;
    }
    &__list {
        margin-bottom: 20px;
        &-item {
            margin-left: 20px;
            list-style: disc !important;
        }
    }
    &__button {
        font-weight: 500;
        padding: 8px 10px;
        margin-right: 16px;
    }
}

.emptyModal {
    &__body {
        display: flex;
        justify-content: center;
    }
}
