.footer {
    max-width: 800px;
    width: 100%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    flex-direction: column;
    padding-top: 68px;
    padding-bottom: 60px;
    &__wrap {
        width: 100%;
        display: flex;
        justify-content: space-around;
        margin-bottom: 40px;
    }
}
