.createAdd {
    &__save {
        margin-left: 20px;
    }
    &__dropzone {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
    }
    &__button {
        text-transform: uppercase;
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 103px;
        left: 50%;
        transform: translateX(-50%);
        color: #ffffff;
        span {
            margin-left: 16px;
        }
    }
    &__create {
        margin-left: auto;
        margin-bottom: 40px;
    }
    &__wrap {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: row;
    }
}

.adds {
    &__block {
        max-width: 500px;
    }
    &__card {
        border: 1px solid #dedede;
        border-radius: 4px;
        text-align: center;
        margin-left: 24px;
        &-top {
            height: 44px;
            padding: 0 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #378cf0;
        }
        &-bottom {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 44px;
            padding: 0 22px;
        }
    }
    &__wrap {
        position: relative;
        align-items: initial;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    &__label {
        margin-bottom: 8px;
        display: block;
    }
    &__searchPannel {
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
        form {
            max-width: 576px;
        }
    }
    &__pagination {
        font-size: 12px;
        line-height: 16px;
        text-align: right;
        padding-right: 25px;
        letter-spacing: 0.4px;
        color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 24px;
        select {
            margin-left: 6px;
            border: none;
            outline: none;
            color: #2e8299;
            font-size: 12px;
            line-height: 16px;
        }
        &-button {
            color: #2e8299;
            font-size: 18px;
            margin-left: 37px;
        }
    }
}

.filebox {
    width: 468px;
    height: 468px;
    background: #bfd4e4;
    border-radius: 16px;
}

.disabled {
    opacity: 0.6;
    cursor: default;
}
