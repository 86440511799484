.password-eye {
    position: absolute;
    right: 12px;
    top: 0;
    svg {
        object-fit: cover;
        // width: 100%;
        // height: 100%;
    }
    &--grey {
        stroke: #bdbdbd path {
            fill: #bdbdbd;
        }
    }
    &--dark {
        // stroke: rgba(0, 0, 0, 0.6);
        path {
            fill: rgba(0, 0, 0, 0.6);
        }
    }
    // &::after {
    //     content: "";
    //     width: 100%;
    //     height: 100%;
    //     top: 0;
    //     left: 0;
    // }
    // top: 50%;
    // transform: translateY(-50%);
}
