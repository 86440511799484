/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .createPost {
    &__list {
      width: 300px;
      margin-bottom: 20px;
    }
    &__form {
      width: 300px;
      margin-left: auto;
      margin-right: auto;
      position: relative;
    }
    &__cancel {
      width: 320px;
    }
    &__next {
      color: #f5f4e8;
      width: 320px;
      margin-bottom: 20px;
    }
    &__goBack {
      img {
        height: 17px;
        width: 17px;
      }
    }
  }

  .chooseFilter {
    &__preview {
      width: 450px;
      height: 450px;
    }
    &__subtitle {
      margin-top: 40px;
      margin-bottom: 40px;
      text-align: center;
    }
    &__filters {
      margin-top: 11px;
    }
  }

  .filters {
    width: 450px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    &__thumbnail {
      margin-bottom: 20px;
    }
  }

  .filebox {
    width: 360px;
    height: 360px;
    &__text {
      position: absolute;
      bottom: 120px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 10px;
    }
  }

  .chooseText {
    &__input {
      width: 400px;
      height: 340px;
    }
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (max-width: 480px) {
  .createPost {
    &__list {
      width: 270px;
      margin-bottom: 20px;
    }
    &__buttons {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
    }
    &__editButtons {
      top: 116%;
      left: 50%;
      transform: translateX(-50%);
    }
    &__cancel {
      width: 152px;
      margin-bottom: 10px;
    }
    &__next {
      color: #f5f4e8;
      width: 152px;
      margin-bottom: 20px;
    }
    &__form {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      position: relative;
    }
    &__goBack {
      position: absolute;
      top: 15px;
      right: 15px;

      img {
        height: 15px;
        width: 15px;
      }
    }
  }

  .filebox {
    width: 90%;
    height: 270px;
    &__text {
      position: absolute;
      bottom: 40px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 10px;
    }
  }

  .filters {
    width: 290px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    &__thumbnail {
      margin-bottom: 20px;
    }
  }

  .chooseFilter {
    &__preview {
      width: 270px;
      height: 270px;
    }
  }

  .chooseText {
    &__input {
      width: 100%;
      height: 240px;
      font-size: 14px;
      padding: 20px 40px;
    }
  }
}
