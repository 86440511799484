// .dropzone {
//     background-color: red;
// }

.selectFile {
  &__dropzone {
    display: flex;
    flex-direction: column;
    justify-content: flex-end !important;
    align-items: center;
    padding-bottom: 180px;
  }
  &__button {
    display: block;
    color: #f5f4e8;
    margin-bottom: 24px;
  }
  &__header {
    font-size: 26px;
    margin-bottom: 21px;
  }
  &__text {
    margin-bottom: 51px;
  }
}

.selectFileWidth {
  position: absolute;
  z-index: 99;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 56px;
  padding-top: 25px;
  width: 895px;
  border: none;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
}

.selectBackgroundFile {
  position: absolute;
  z-index: 99;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 20px;
  padding-top: 25px;
  max-width: 895px;
  width: 100%;
  border: none;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  max-height: 90vh;
  @media screen and (max-width: 500px) {
    p {
      margin: 0;
    }
  }
  .createPost__buttons {
    button {
      margin-right: 20px;
      width: 170px;
      margin-bottom: 0;
    }
  }
  &__content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    min-height: 260px;
    overflow: hidden;
    overflow-y: auto;
    padding-top: 20px 0 10px;
  }
  &__card {
    height: 250px;
    width: 250px;
    margin: 5px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-weight: 600;
    font-size: 23px;
    line-height: 34px;
    cursor: pointer;
    border-radius: 20px;
    position: relative;
    border: 3px solid rgba(#000000, 0);
    &-active {
      border: 3px solid #2e8299;
    }

    span {
      margin: 0 15px;
      text-align: center;
    }

    img {
      position: absolute;
      height: 40px;
      width: 40px;
      top: -10px;
      right: -10px;
    }
  }
}

.titleCardBackground {
  word-break: break-word;
  font-family: 'Kalam', cursive;
  font-size: 23px;
  line-height: 25px;
}
