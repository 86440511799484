.navbar {
    margin-bottom: 15px;
    &__container {
        z-index: 100;
        min-height: 80px;
        display: flex;
        justify-content: space-between;
        padding-right: 30px;
        padding-left: 30px;
        margin-right: auto;
        margin-left: auto;
        position: relative;
        max-width: 2000px;
        width: 100%;
    }
    &__fullName {
        color: rgba(#f5f4e8, 0.8);
        margin-right: 12px;
    }
    &__logo {
        &--logged {
            position: relative;
            display: flex;
            align-items: center;
            &:after {
                margin-top: 10px;
                margin-left: -30px;
                content: "Admin";
                display: block;
                font-weight: 500;
                font-size: 24px;
                line-height: 28px;
                color: #ee4c6e;
            }
        }
    }
    &__buttons {
        &-register {
            margin-right: 20px;
        }
    }
    &--dark {
        background: #050138;
    }
    &__container {
        display: flex;
        align-items: center;
    }
    &__avatar {
        margin-right: 14px;
        border-left: 1px solid #2e8299;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 25px;
    }
    &__settings {
        display: flex;
        align-items: center;
        margin-left: 0;
        position: relative;
    }
    &__help {
        margin-right: 24px;
        margin-left: 16px;
    }
}

.searchbar {
    width: 100%;
    position: relative;
    &__input {
        width: 100%;
        background-color: #fff;
        padding-left: 56px;
        margin-bottom: 0;
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
        border: 1px solid transparent;
        &::placeholder {
            color: #828282;
        }
    }
    &__button {
        position: absolute;
        left: 19px;
        top: 50%;
        transform: translateY(-50%);
    }
    &__hint {
        padding-bottom: 20px;
        width: 500px;
    }
    &__tabs {
        display: flex;
        justify-content: space-between;
    }
    &__tab {
        flex-basis: 50%;
        text-align: center;
        padding: 20px 0;
        transition: background-color 0.2s ease-in;
        &--active {
            background-color: rgba(#2e8299, 0.1);
        }
    }
    &__item {
        transition: background-color 0.2s ease-in;
        &:hover {
            background-color: rgba(#2e8299, 0.1);
            cursor: pointer;
        }
    }
}

@media (max-width: 1200px) {
    .searchbar {
        width: 100%;
        margin-left: 0px;
        margin-right: 50px;
        &__hint {
            left: 0;
        }
    }
}

@media (max-width: 992px) {
}

@media (max-width: 768px) {
    .searchbar {
        margin-left: 50px;
        &__hint {
            width: 380px;
            left: -50px;
        }
    }
}

@media (max-width: 576px) {
    .navbar {
        &__logo {
            width: 150px;
            img {
                max-width: 100%;
            }
        }
        &__container {
            flex-wrap: wrap;
            padding-left: 25px;
            padding-right: 25px;
        }
        &__settings {
            margin-left: 0;
        }
        &__avatar {
            margin-right: 10px;
        }
        padding-bottom: 15px;
    }

    .searchbar {
        margin-left: 0;
        margin-right: 0;

        margin-top: 15px;
        order: 2;
        &__hint {
            width: 100%;
            left: 0;
        }
        &__input {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
}
