@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kalam&display=swap");

* {
  font-family: Roboto, Helvetica, sans-serif;
  box-sizing: border-box;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

image {
  max-width: 100%;
}

a {
  text-decoration: none;
  color: #2e8299;
}

body {
  background-color: #ffffff;
}

input,
button,
textarea {
  outline: none;
  border: none;
  background: transparent;
}

button {
  cursor: pointer;
  padding: 0;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

body {
  margin: 0;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

#root {
  position: relative;
}

.link {
  color: #2e8299;
  cursor: pointer;
  &--underlined {
    text-decoration: underline;
  }
  &--normal {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    letter-spacing: 0.15px;
  }
  &--small {
    font-size: 14px;
    line-height: 16px;
    opacity: 0.8;
  }
}

.header {
  font-weight: 400;
  color: #000000;
  &--one {
    line-height: 56px;
    font-size: 28px;
    font-weight: 400;
  }
  &--two {
    font-size: 12px;
    font-weight: 500;
  }
  &--three {
    font-size: 24px;
    line-height: 28px;
    font-weight: 400;
  }
  &--four {
    font-size: 21px;
  }
}

.subtitle {
  font-weight: 500;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  max-width: 669px;
  color: #111424;
}

.label {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #111424;
  opacity: 0.8;
}

.text {
  color: #050138;
  &--normal {
    font-size: 16px;
    line-height: 19px;
  }
  &--bold {
    font-weight: 500;
  }
  &--small {
    font-size: 14px;
    line-height: 16px;
  }
  &--tiny {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
  }
  &--center {
    text-align: center;
  }
}

.form {
  width: 340px;

  &__group {
    margin-top: 28px;
    position: relative;
  }
}

.formInput {
  width: 100%;
  border: 1px solid rgba(#050138, 0.4);
  border-radius: 4px;
  padding-left: 20px;
  letter-spacing: 0.15px;
  line-height: 24px;
  padding-right: 25px;
  padding-top: 14px;
  padding-bottom: 14px;
  color: #111424;
  font-size: 16px;
  transition: border 0.2s ease-in-out;
  margin-bottom: 4px;
  transition: border 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  &::placeholder {
    color: rgba(#050138, 0.6);
  }
  &:focus ~ .formNotification {
    opacity: 1;
  }
  &:focus {
    box-shadow: 0px 0px 0px 2px #050138;
    border: 1px solid transparent;
  }
  &--focused {
    box-shadow: 0px 0px 0px 2px #050138;
    border: 1px solid transparent;
  }
  &--focused ~ .formNotification {
    opacity: 1;
  }

  // &:disabled {
  //     background: #dadada;
  //     border: none;
  // }
}

.inputError ~ .formNotification {
  opacity: 1;
  color: #da100b;
}

.inputError {
  border: 1px solid transparent;
  box-shadow: 0px 0px 0px 2px #da100b;
  &:focus {
    box-shadow: 0px 0px 0px 2px #da100b;
    border: 1px solid transparent;
  }
}

.formLabel {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.formNotification {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
  opacity: 0.6;
  &--active {
    opacity: 1;
  }
  /* identical to box height, or 133% */
}

.wrap {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
  border-radius: 8px;
  padding: 60px 30px 75px 30px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1087px;
  position: relative;
  margin-top: 120px;
}

.container {
  padding-left: 300px;
  padding-right: 15px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

.button {
  cursor: pointer;
  padding: 10px 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  border-radius: 4px;
  letter-spacing: 1.25px;
  transition: background 0.2s ease-in-out, border 0.2s ease-in-out, color 0.2s ease-in-out;
  text-align: center;
  &:disabled {
    opacity: 0.7;
  }
  &--primary {
    background: #2e8299;
    &:hover,
    &:focus {
      background: #050138;
    }
  }
  &--outlined {
    &:hover {
      border: 1px solid rgba(0, 0, 0, 0.12);
      background: rgba(98, 0, 238, 0.04);
      color: #6200ee;
    }
    &:focus {
      background: rgba(98, 0, 238, 0.12);
      border: 1px solid rgba(0, 0, 0, 0.12);
      color: #6200ee;
    }
    border: 1px solid #2e8299;
    background: transparent;
    color: #2e8299;
  }
}

.hint {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
  border-radius: 8px;
  z-index: 10000;
  position: absolute;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  width: 440px;
  left: 50%;
  transform: translateX(-50%);
  top: 70%;
  &__header {
    margin-left: 20px;
    margin-bottom: 20px;
  }
  &__category {
    display: flex;
    padding-left: 33px;
    padding-top: 25px;
    padding-bottom: 25px;
    align-items: center;
    transition: 0.2s ease-in;
    border-bottom: 1px solid #e0e0e0;
    &:hover {
      background-color: rgba(#2e8299, 0.1);
    }
    &--focused {
      background-color: rgba(#2e8299, 0.1);
    }
    &-img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-color: #2e8299;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
    }
    &-info {
      display: flex;
      flex-direction: column;
    }

    &-posts {
      opacity: 0.6;
    }
  }
}

.sortButton {
  display: flex;
  align-items: center;
  span {
    color: #2e8299;
    margin-left: 8px;
  }
}

.searchPannel {
  display: flex;
  width: 100%;
  form {
    margin-right: 200px;
  }
}

.ReactModal__Overlay {
  z-index: 1000 !important;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  z-index: 1000 !important;
  // overflow-y: auto !important;
}

.show {
  opacity: 1 !important;
  pointer-events: auto !important;
}

.hide {
  opacity: 0;
  pointer-events: none;
  transform: scale(0) !important;
}

.home {
  &__container {
    display: flex;
    justify-content: center;
  }
  &__text {
    text-align: center;
    max-width: 600px;
    color: #000000;
    font-weight: 400;
  }
  &__wrap {
    position: relative;
    margin-left: 0;
    margin-right: 25px;
    min-height: 80vh;
    min-width: 1087px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0;
    flex-wrap: wrap;
    &-block {
      background-color: rgba(#f2f2f2, 0.2);
      border-radius: 16px;
      width: 720px;
    }
  }
  &__buttons {
    display: flex;
    flex-direction: column;
    width: 174px;
    .button {
      padding-left: 0;
      padding-right: 0;
      &--primary {
        color: #f5f4e8;
        margin-bottom: 16px;
      }
    }
  }
  &__button {
    position: fixed;
    bottom: 25px;
    left: 50%;
    margin-left: 450px;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    background-color: #050138;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in;
    cursor: pointer;
    &:hover {
      transform: scale(110%);
    }
    img {
      width: 30px;
      height: 30px;
    }
  }
}

.editors-choice {
  &::after {
    content: "Editor's choice";
    position: absolute;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #050138;
  }
  &--album {
    top: 8px;
    right: 8px;
  }
  &--post {
    top: 20px;
    right: 20px;
  }
}

.progress-bar {
  width: 100px;
  height: 100px;
}

.status {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  padding: 4px 10px;
  border-radius: 4px;
  &--pending {
    color: #378cf0;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #378cf0;
    border: 1px solid #378cf0;
  }
  &--paused {
    color: #f2994a;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #f2994a;
    border: 1px solid #f2994a;
  }
  &--running {
    color: #3cc13b;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #3cc13b;
    border: 1px solid #3cc13b;
  }
  &--stopped {
    color: #f03738;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #f03738;
    border: 1px solid #f03738;
  }
  &--finished {
    color: #9fa2b4;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #9fa2b4;
    border: 1px solid #9fa2b4;
  }
}

.coolBlue {
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: radial-gradient(circle, rgba(146, 164, 255, 0.4) 0%, rgba(3, 14, 70, 0.4) 100%);
  }
}

.coolWarm {
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: yellow;
    opacity: 0.3;
  }
}

.normal {
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }
}

.tabs {
  border-bottom: 1px solid #2e8299 !important;
  flex-basis: 100%;
  width: 100%;
  margin-bottom: 28px;
  display: flex;
}

.tab {
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #828282;
  border-bottom: 4px solid transparent !important;
  width: 25%;
  padding: 17px 0 !important;
  transition: border-bottom 0.2s ease-in, color 0.2s ease-in;
  display: block;
  &--active {
    color: #373460;
    border: none !important;
    background: #EEF5F7;
  }
}

.react-tabs__tab {
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: rgba(#2e8299, 0.8);
  border-bottom: 4px solid transparent !important;
  width: 150px;
  padding: 17px 0 !important;
  transition: border-bottom 0.2s ease-in, color 0.2s ease-in;
}

.react-tabs__tab--selected {
  border: none !important;
  border-bottom: 4px solid #ee4c6e !important;
}

.display-none {
  display: none !important;
}

.revert-button {
  position: fixed;
  bottom: 25px;
  left: 50%;
  margin-left: 700px;
}

.refresh-button {
  position: fixed;
  top: 15px;
  // background-color: #fff;
  left: 50%;
  transform: translateX(-50%);
  width: 25px;
  height: 25px;
  z-index: 1000;
  transition: transform 0.1s ease-in;
  &:hover {
    transform: scale(110%) translateX(-50%);
  }
  img {
    max-width: 100%;
  }
}

.ql-tooltip.ql-editing {
  z-index: 100 !important;
}

.ql-editor {
  font-size: 16px;
  // min-height: 200px;
}

.ql-toolbar.ql-snow .ql-picker-label svg polygon {
  stroke: #000000 !important;
}

.ql-container.ql-snow {
  border: none !important;
}

.ql-toolbar.ql-snow {
  border: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.ql-container {
  border-radius: 12px;
}

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #050138 !important;
}

.ql-toolbar.ql-snow {
  padding: 12px !important;
  border-bottom: 8px;
}

.ql-snow.ql-toolbar button .ql-snow.ql-toolbar button {
  &:hover ~ path {
    stroke: red !important;
  }
  // fill: red !important;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 100%;
    max-width: 1440px;
  }
}
