.auth {
    &__container {
        min-height: 70vh;
        .react-ripples {
            margin-left: auto;
            margin-right: auto;
            display: flex !important;
            justify-content: center;
        }
    }
    &__subtitle {
        margin-bottom: 40px;
    }
    &__button {
        margin: 0 auto;
        margin-top: 24px;
        display: block;
    }
    &__header {
        &--one {
            margin-bottom: 40px;
        }
        &--two {
            margin-bottom: 27px;
            margin-top: 38px;
        }
    }

    &__checkbox-label {
        margin-left: 11px !important;
    }
}

.logIn {
    &__link {
        margin-top: 24px;
        margin-bottom: 40px;
        display: block;
    }
}

.register {
    &__form-group {
        margin-top: 13px;
    }
    &__form-button {
        width: 176px;
    }
    &__subtitle {
        max-width: 631px;
        margin-bottom: 15px;
    }
    &__header--one {
        margin-bottom: 20px;
    }
    &__header--two {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    &__policy {
        margin-bottom: 20px !important;
        margin-top: 20px !important;
    }
    &__button {
        margin-top: 15px;
        margin-bottom: 15px;
        margin-left: auto;
        margin-right: auto;
    }
}

.forgotPassword {
    &__subtitle {
        margin-bottom: 12px;
    }
    &__button {
        margin-top: 24px;
        padding: 10px 33px;
        color: #f5f4e8;
    }
}

.policy {
    &__text {
        letter-spacing: 0.15px;
        color: rgba(0, 0, 0, 0.6);
        text-align: center;
        margin-top: 40px;
        max-width: 328px;
        margin-bottom: 40px;
    }
}

.passwordReset {
    &__group {
        display: flex;
        margin-top: 30px;
        margin-bottom: 40px;
    }
    &__link {
        margin-left: 5px;
        color: #050138;
    }
}
