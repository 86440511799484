.navigation {
    display: flex;
    align-items: center;
    margin-bottom: 29px;
    position: absolute;
    flex-wrap: wrap;
    width: 100%;
    // top: -100%;
    top: -23px;
    transform: translateY(-100%);
    left: 0;
    &__currentPage {
        margin-left: 19px;
        // max-width: 75%;
        word-break: break-word;
        flex-basis: 70%;
    }
    &__prevPage {
        cursor: pointer;
        color: rgba(#111424, 0.6);
        // flex-basis: 13%;
    }
}
