.post {
  &__container {
    margin-bottom: 50px;
    display: flex;
    // align-items: center;
    // justify-content: center;
    flex-wrap: wrap;
  }
  &__wrap {
    width: 723px;
    display: flex;
    flex-direction: column;
    position: relative;
    // background-color: rgba(#f2f2f2, 0.2);
    border-radius: 16px;
    padding: 20px 70px 0px 50px;
    border: 1px dashed transparent;
    transition: border 0.1s ease-in;
    &--active {
      border: 1px dashed #2e8299;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #3e66fb;
    }
  }
  &__block {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  &__user {
    display: flex;
    align-items: center;
    &-fullName {
      margin-left: 16px;
    }
  }
  &__date {
    color: rgba(#050138, 0.6);
  }
  &__preview {
    width: 600px;
    height: 600px;
    margin-top: 20px;
    position: relative;
  }
  &__category {
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 10px;
    margin-top: 15px;
    display: flex;
    align-items: flex-start;
    &-link {
      padding: 2px 8px;
      // width: fit-content;
      font-weight: 500;
      border-radius: 4px;
      word-break: break-all;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #ffffff;
      background: #da100b;
    }
    &-name {
      flex-basis: 25%;
      display: flex;
      justify-content: flex-end;
    }
    &-admin {
      position: absolute;
      right: -65px;
      transform: rotate(90deg) translateY(-50%);
      top: 50%;
    }
    &-title {
      flex-basis: 48%;
      text-align: center;
      color: #da100b;
      word-break: break-word;
    }
  }
  &__buttons {
    margin-top: 15px;
    margin-left: 9px;
    margin-bottom: 10px;
    height: 28px;
    position: relative;
    width: fit-content;
    &-like {
      margin-right: 21px;
    }
  }
  &__form {
    margin-top: 8px;
    width: 100%;
    position: relative;
    &-input {
      resize: none;
      padding: 14px 82px 14px 19px;
      background: rgba(#2e8299, 0.2);
      overflow: hidden !important;
      border-radius: 8px;
      width: 100%;
    }
    &-button {
      position: absolute;
      padding: 14px 19px;
      right: 0;
      top: 0;
      transition: opacity 0.1s ease-in;
      cursor: default;
      opacity: 0.5;
      &--active {
        cursor: pointer;
        opacity: 1;
      }
    }
  }
  &__expand-button {
    transform: rotate(180deg);
    &--active {
      transform: rotate(0);
    }
  }
  &__actions {
    display: flex;
    // background-color: #fff;
    position: absolute;
    left: 106%;
    width: 319px;
    bottom: 10px;
    &-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      &:nth-child(1) {
        margin-right: 60px;
      }
      &:nth-child(2) {
        margin-right: 33px;
      }
      &:nth-last-child(1) {
        margin-right: 0;
      }
    }
    &-text {
      position: absolute;
      bottom: -15px;
      width: max-content;
      letter-spacing: 0.4px;
    }
    &-button {
      margin-bottom: 8px;
      width: 52px;
      height: 52px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #3e66fb;
      border-radius: 8px;
    }
    &-hint {
      position: absolute;
      background-color: #fff;
      width: 340px;
      display: flex;
      flex-direction: column;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
      border-radius: 8px;
      right: 0;
      top: 23px;
      z-index: 100;
      padding: 10px 0;
    }
    &-hintClose {
      position: absolute;
      padding: 15px;
      right: 0;
      top: 0px;
    }
    &-hintElement {
      padding: 25px 34px;
      border-bottom: 1px solid #e0e0e0;
      background-color: #fff;
      transition: background-color 0.2s ease-in;
      cursor: pointer;
      &:hover {
        background-color: rgba(#2e8299, 0.1);
      }
    }
  }
  &__bottomComments {
    width: 723px;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0px 70px 0px 50px;
    &-button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 19px;
    }
  }
}

.comments {
  &__block {
    display: flex;
    position: relative;
    flex-direction: column;
    margin-top: 5px;
    margin-bottom: 5px;
    border: 1px dashed transparent;
    // transition: border 0.2s ease-in;

    padding-top: 10px;
    padding-bottom: 13px;
    padding-right: 45px;
    border-radius: 16px;
    &--active {
      &::before {
        border-radius: 16px;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        border: 1px dashed #2e8299;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #3e66fb;
        width: 723px;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 52%;
      }
    }
  }
  &__pinIcon {
    width: 10px;
    height: 16px;
    position: absolute;
    left: -8px;
    transform: translate(-100%, 50%);
    top: 0;
  }
  &__pinButton {
    margin-right: 10px;
  }
  &__top {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
  &__edit {
    margin-right: 10px;
    color: #2e8299;
    img {
      width: 15px;
      height: 15px;
    }
  }
  &__delete {
    img {
      width: 15px;
      height: 15px;
    }
  }
  &__block.pinned {
    img {
      display: block;
    }
  }
  &__fullName {
    margin-right: 8px;
    min-width: fit-content;
    margin-bottom: 7px;
    z-index: 50;
  }
  &__text {
    position: relative;
    width: 100%;
    word-break: break-word;
    &-button {
      // position: absolute;
      // right: 44px;
      // bottom: 13px;
      margin-top: 10px;
      text-align: right;
    }
    &--warning {
      background-color: rgba(#da100b, 0.3);
    }
  }
  &__button {
    margin-top: 8px;
    margin-bottom: 8px;
    display: block;
  }
  &__likes {
    display: block;
    flex-basis: 25%;
  }
  .ReactModal__Overlay--after-open {
    position: relative !important;
  }
}

.previewStreams {
  width: 600px;
  height: 524px;
  position: relative;
  border-radius: 15px;
  background-color: rgba(#050138, 0.2);
  overflow: hidden;
  margin-top: 15px;

  &__audio {
    img {
      width: 331px;
      height: 152px;
    }
  }
  &__video {
    background-color: #000;
    display: flex;
    align-items: center;

    &-icon {
      position: absolute;
      width: 25px;
      height: 25px;
      bottom: 25px;
      right: 25px;
    }
    video {
      object-fit: cover;
      width: 100%;
      height: 100%;
      transform: scale(-1, 1);
    }
  }
  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      object-fit: cover;
      width: 100%;
    }
    p {
      font-family: 'Kalam', cursive;
      font-style: normal;
      font-weight: 600;
      font-size: 23px;
      line-height: 34px;
      text-align: center;
      opacity: 0.7;
      color: #ffffff;
      @media screen and (min-width: 480px) {
        font-size: 50px;
        line-height: 50px;
      }
      @media screen and (min-width: 770px) {
        font-size: 70px;
        line-height: 70px;
      }
    }
    &--defaultImage {
      &::before {
        content: 'Read the text below';
        bottom: 26px;
        right: 29px;
        position: absolute;
        font-size: 16px;
        line-height: 19px;
      }
      align-items: center;
      display: flex;
      justify-content: center;
      img {
        width: 445px;
      }
    }
  }
}

.preview {
  width: 600px;
  height: 524px;
  position: relative;
  border-radius: 15px;
  background-color: rgba(#050138, 0.2);
  overflow: hidden;
  margin-top: 15px;

  &__audio {
    img {
      width: 331px;
      height: 152px;
    }
  }
  &__video {
    background-color: #000;
    display: flex;
    align-items: center;

    &-icon {
      position: absolute;
      width: 25px;
      height: 25px;
      bottom: 25px;
      right: 25px;
    }
    video {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      object-fit: cover;
      width: 100%;
    }
    p {
      font-family: 'Kalam', cursive;
      font-style: normal;
      font-weight: 600;
      font-size: 23px;
      line-height: 34px;
      text-align: center;
      opacity: 0.7;
      color: #ffffff;
      @media screen and (min-width: 480px) {
        font-size: 50px;
        line-height: 50px;
      }
      @media screen and (min-width: 770px) {
        font-size: 70px;
        line-height: 70px;
      }
    }
    &--defaultImage {
      &::before {
        content: 'Read the text below';
        bottom: 26px;
        right: 29px;
        position: absolute;
        font-size: 16px;
        line-height: 19px;
      }
      align-items: center;
      display: flex;
      justify-content: center;
      img {
        width: 445px;
      }
    }
  }
}

// .chooseText {
//     &__input {
//         width: 600px;
//         background: rgba(#2e8299, 0.2);
//         resize: none;
//         border-radius: 8px;
//         height: 140px;
//         padding: 14px 19px;
//         font-size: 16px;
//         line-height: 24px;
//         letter-spacing: 0.15px;
//         color: #111424;
//         margin-top: 25px;
//         &::placeholder {
//             color: rgba(#111424, 0.8);
//         }
//     }
//     &__preview {
//         background-color: rgba(#050138, 0.2);
//         border-radius: 16px;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         width: 600px;
//         height: 600px;
//         margin-bottom: 40px;
//         position: relative;
//     }
//     &__audioImage {
//         position: absolute;
//         left: 50%;
//         top: 50%;
//         transform: translate(-50%, -50%);
//     }
//     &__audioButton {
//         position: absolute;
//         left: 50%;
//         top: 50%;
//         transform: translate(-50%, -50%);
//         img {
//             width: 150px;
//             height: 150px;
//         }
//     }
// }
