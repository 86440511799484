.aside {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    width: 255px;

    background-color: #050138;
    padding-top: 128px;
    min-height: 100%;
    &__list {
        &-item {
            transition: color 0.2s ease-in, background 0.2s ease-in, border-left 0.2s ease-in;
            width: 100%;
            display: flex;
            align-items: center;
            padding-top: 18px;
            padding-bottom: 18px;
            padding-left: 52px;
            color: #a4a6b3;
            font-size: 16px;
            line-height: 19px;
            position: relative;
            border-left: 3px solid transparent;

            &--active {
                color: #ee4c6e;
                background: rgba(#9fa2b4, 0.08);
                border-left: 3px solid #ee4c6e;
                .aside__expandIcon {
                    transform: rotate(0);
                }
            }
        }
        &-name {
            margin-left: 20px;
            max-width: 159px;
        }
        &-image {
            position: absolute;
            left: 29px;
        }
    }
    &__expandIcon {
        transform: rotate(180deg);
        margin-left: 20px;
    }
}
